<div class="logo">
    <img class="logo-img" src="assets/logo.png" alt="debate-z">
</div>
<div class="row  justify-content-center h-100" style="margin-right: 0px; margin-left: 0px;">

    <div class="col-sm-6 col-md-6 col-lg-6 peerContainer">
        <video id="my-video" class="videoElement " autoplay playsinline muted="muted">

        </video>
        <div class="nameControlBar">
            <div class="nameControlDiv">
                <div>
                    <div class="controlNameHover nameHolder"><span class="d-inline-block text-truncate"
                            style="max-width: 70px;">
                        </span> <span class="logged-in">●</span>&nbsp;{{socketService.userName}} /
                        {{socketService.profession}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 peerContainer">
        <div>
            <ng-container *ngIf="!peerService.partnerConnected">


                <div class=" waitingState text-center">
                    <div>
                        <div>
                            <div class="row">
                                <div id="loader">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="loading">Finding Like-Minded Person...</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </ng-container>
            <video muted="muted" [class.displayNone]="!peerService.partnerConnected"
                [class.displayShow]="peerService.partnerConnected" id="partner-video" class="videoe-m" autoplay
                playsinline #myPartner></video>

            <div class="nameControlBar partnerName" *ngIf="peerService.partnerConnected">
                <div class="nameControlDiv" *ngIf="peerService.partnerConnected">
                    <div>
                        <div class="controlNameHover nameHolder"><span class="d-inline-block text-truncate"
                                style="max-width: 70px;"> </span>
                            <span class="logged-in">●</span>&nbsp;{{socketService.partnerName}} /
                            {{socketService.partnerProfession}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="buttons-container">
        <div class="player-buttons">
            <div class="arrowContainer"><i class="arrow"></i></div>
            <div class="controlDiv">

                <div class="control-button video-button" *ngIf="peerService.userVideo" (click)="stopVideo()">
                    <i class="fa fa-video"></i>
                </div>

                <div class="control-button video-button" *ngIf="!peerService.userVideo" (click)="stopVideo()">
                    <i class="fa fa-video-slash"></i>
                </div>

                <div class="control-button mute-button" *ngIf="peerService.userMic" (click)="muteVideo()">
                    <i class="fas fa-microphone"></i>
                </div>

                <div class="control-button mute-button" *ngIf="!peerService.userMic" (click)="muteVideo()">
                    <i class="fa fa-microphone-slash"></i>
                </div>

                <div class="control-button" (click)="peerService.fallBack()">
                    <i class="fa fa-phone-slash" style="text-align: center;"></i>
                </div>
            </div>

        </div>
    </div>
</div>

<!--
<div class="row d-flex justify-content-center" style="margin-right: 0px; margin-left: 0px">
    <div class="controlContainer shadow rounded" style="background-color: #0F74F0;">
        <div class=" d-flex align-self-end justify-content-center boderColor controlItemsVideoOverlap" style="width: 70px;">
            <span class="d-inline-block text-truncate" style="max-width: 70px;">
               <div>
                    <span class="logged-in">●</span> {{socketService.userName}}
                </div>
            </span>

            <span class="logged-in">●</span></div>
        <div class="boderColor controlItemsVideoOverlap " *ngIf="!peerService.userVideo" (click)="stopVideo()"> <i class="fa fa-video-slash m-1 controlIcon"></i></div>
        <div class="boderColor controlItemsVideoOverlap" *ngIf="peerService.userVideo" (click)="stopVideo()"> <i class="fa fa-video m-1 controlIcon"></i></div>
        <div class="boderColor controlItemsVideoOverlap" *ngIf="peerService.userMic" (click)="muteVideo()"> <i class="fa fa-microphone m-1 controlIcon microphone"></i></div>
        <div class="boderColor controlItemsVideoOverlap" *ngIf="!peerService.userMic" (click)="muteVideo()"> <i class="fa fa-microphone-slash m-1 controlIcon microphone"></i></div>
    </div>
</div> -->