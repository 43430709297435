import { PeerService } from './../../../service/peer.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { SocketService } from 'src/app/service/socket.service';

@Component({
  selector: 'app-sidelayout',
  templateUrl: './sidelayout.component.html',
  styleUrls: ['./sidelayout.component.scss']
})
export class SidelayoutComponent implements OnInit {
  myEl: HTMLMediaElement;
  mainVid: HTMLMediaElement;
  partnerEl: HTMLMediaElement;
  constructor(public peerService: PeerService, public socketService: SocketService,  public elRef: ElementRef) { }

  ngOnInit(): void {
    this.setElement();
  }

  setElement(): void{
    this.myEl = this.elRef.nativeElement.querySelector('#my-video');
    this.partnerEl = this.elRef.nativeElement.querySelector('#partner-video');
    this.peerService.setElements(this.myEl, this.partnerEl);

  }
  stopVideo(): void {
    this.peerService.playStop();
    this.peerService.userVideo = !this.peerService.userVideo;
  }
  muteVideo(): void  {
    this.peerService.muteUnmute();
    this.peerService.userMic = !this.peerService.userMic;
  }

  stopVideoPartner(): void {
    this.peerService.playStopPartner();
    this.peerService.partnerVideo = !this.peerService.partnerVideo;
  }
  muteVideoPartner(): void  {
    this.peerService.muteUnmutePartner();
    this.peerService.partnerMic = !this.peerService.partnerMic;
  }

}
