import { NameComponent } from './../modal/name/name.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PeerService } from './../../service/peer.service';
import { SocketService } from './../../service/socket.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Constants } from 'src/app/Constants/app.constants';
import { InfoComponent } from '../modal/info/info.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  layout: boolean;
  private modalRef: NgbModalRef;
  private modalNameRef: NgbModalRef;
  myEl: HTMLMediaElement;
  mainVid: HTMLMediaElement;
  partnerEl: HTMLMediaElement;
  private id;

  private username;
  private role;
  private studentname;
  private fallback;

  constructor(private socketService: SocketService,
    private peerService: PeerService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router,
    public elRef: ElementRef,
    private route: ActivatedRoute) {
    this.layout = true;

    this.route.queryParams.subscribe(params => {
      this.username = params['username'];
      this.role = params['role'];
      this.studentname = params['studentname'];
      this.fallback = params['fallback'];

      console.log("username: ", this.username, " role: ", this.role);
      //Swal.fire('Hi :', this.username);

    });


  }

  ngOnInit(): void {

    this.componentBootstrap();

  }
  componentBootstrap(): void {
    try { this.modalService.dismissAll(); } catch (err) { }
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    if (this.username) {
      this.socketService.userName = this.username;
      this.peerService.userName = this.username;
      this.peerService.fallback = this.fallback;

      if (this.role == "tutor") {
        Swal.fire({
          icon: 'warning',
          title: 'Call Request',
          text: `Student with username (${this.studentname}) wants to have this session with you`,
          showDenyButton: true,
          confirmButtonText: 'Accept',
          confirmButtonColor: '#fa005e',
          denyButtonText: `Reject`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.modalRef = this.modalService.open(InfoComponent, ngbModalOptions);
            this.modalRef.result.then((result) => {
              this.initialPeerSetup(this.id, result);
              this.initializeConnection();
            });
          } else if (result.isDenied) {
            this.initializeConnection();
            console.log("Rejecting Call Request:", this.id)
            this.socketService.socket.emit(Constants.SOCKET_END_CALL, this.id, this.username);
            setTimeout(() => {
              window.location.href = this.fallback;
            }, 300)

          }
        })
      }
      else {
        this.modalRef = this.modalService.open(InfoComponent, ngbModalOptions);
        this.modalRef.result.then((result) => {
          this.initialPeerSetup(this.id, result);
          this.initializeConnection();
        });
      }

    }

    else {
      this.initializeConnection();
      this.modalNameRef = this.modalService.open(NameComponent, ngbModalOptions);
      this.modalNameRef.result.then((result) => {
        this.modalRef = this.modalService.open(InfoComponent, ngbModalOptions);
        this.modalRef.result.then((result) => {
          this.initialPeerSetup(this.id, result);

        });
      });
    }

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');


      if (id === null) {
        const id = uuidv4();
        console.log(id);
        this.router.navigate(['/home', id]);
        return;
      } else {
        console.log('test', id);
        this.id = id;


      }

    });

  }

  initialPeerSetup(id: string, result: any): void {
    this.myEl = this.elRef.nativeElement.querySelector('#my-video');
    this.partnerEl = this.elRef.nativeElement.querySelector('#partner-video');
    this.mainVid = this.elRef.nativeElement.querySelector('#switchVideo');

    this.peerService.init(id, result);
  }

  initializeConnection(): void {
    console.log("initializing socket...");
    this.socketService.initSocket();
    this.socketService.onEvent(Constants.EVENT_CONNECT)
      .subscribe(() => {
        console.log('connected');
      });

    this.socketService.onEvent(Constants.EVENT_DISCONNECT)
      .subscribe(() => {
        console.log('disconnected');
      });
    this.socketService.onUserConnect().subscribe((data) => {
      console.log('Join', data.userId);
      this.peerService.call(data.userId);
      this.socketService.partnerName = data.name;
      this.socketService.partnerProfession = data.profession;
      this.socketService.socket.emit(Constants.SOCKET_SEND_NAME, this.peerService.roomId, this.socketService.userName, this.socketService.profession);
    });

    this.socketService.onNameReceive().subscribe((data) => {
      this.socketService.partnerName = data.name;
      this.socketService.partnerProfession = data.profession;
    });

    this.socketService.onCallReject().subscribe((data) => {
      this.peerService.partnerConnected = false;
    });

    this.socketService.getSocketTopic().subscribe((data) => {
      console.log("topic available:", data);
      this.socketService.topic = data;
    });



    this.socketService.onNotification().subscribe((data) => {
      this.toastrService.info(data.payload);
    });
  }
  stopVideo(): void {
    this.peerService.playStop();
  }
  muteVideo(): void {
    this.peerService.muteUnmute();
  }
  navigate(): void {
    if (this.layout) {
      this.router.navigate(['./overlap'], { relativeTo: this.route });
    } else {
      this.router.navigate(['./side'], { relativeTo: this.route });
    }
    this.layout = !this.layout;
  }
}

