import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'alibutton';

  constructor(){}
  ngOnInit(): void {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    .then(function(stream) {
      console.log('You let me use your mic!')
    })
    .catch(function(err) {
      console.log('No mic for you!')
    });
  }
}
