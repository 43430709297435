import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  @Output() categorySelected = new EventEmitter<string>();
  @Output() subcategorySelected = new EventEmitter<string>();
  selectedCategory: any;


  categories = [
    {
      name: "Fun",
      icon: "Fun.svg",
      subcategories: [
        "Are ghosts real?",
        "Do aliens exist?",
        "Is Black Friday worth it?",
        "Which video game console is the best?",
        "Should cursive be taught to children?"
      ]
    },
    {
      name: "History",
      icon: "History.svg",
      subcategories: [
        "Which would be better to visit: ancient Egypt or ancient Greece?",
        "Was the atomic bombing of Japan justified?",
        "Was the Treaty of Versailles the main cause of World War II?",
        "Should the British Museum return artifacts to their countries of origin?",
        "Which technology had the biggest impact on the world: the railroad, steamship, or telegraph?"
      ]
    },
    {
      name: "Civics",
      icon: "Civics.svg",
      subcategories: [
        "Should vaccines be made mandatory?",
        "Should climate policy be decided by a panel of experts rather than by politicians?",
        "Would it be better to have one universal language?",
        "Is Affirmative Action a desirable policy?",
        "Should citizens have to pass a political knowledge test in order to vote?"
      ]
    },
    {
      name: "Education",
      icon: "Education.svg",
      subcategories: [
        "Should students have to wear school uniforms?",
        "Should students be allowed to use smartphones in school?",
        "Could technology ever replace teachers?",
        "Should a nutrition class be included for grade school and high school?",
        "Should financial literacy classes be required for all students?"
      ]
    },
    {
      name: "Politics & Law",
      icon: "Politics.svg",
      subcategories: [
        "Should countries have quotas to ensure representation of minority groups in parliament?",
        "What provides a better account for the causes of armed conflict: “greed” explanations or “grievance” ones?",
        "Should there be a cap on private donations to electoral campaigns?",
        "What is the best method of constitutional interpretation?",
        "Should strict criminal liability be removed from all imprisonable offenses?"
      ]
    },
    {
      name: "Technology",
      icon: "Technology.svg",
      subcategories: [
        "Should digital devices replace paper and books?",
        "Should deepfake technology be regulated by law?",
        "Do smartphones make us less smart?",
        "Are there more advantages than disadvantages to open-source software?",
        "Is the internet an overall good thing for society?"
      ]
    },
    {
      name: "Literature",
      icon: "Literature.svg",
      subcategories: [
        "Should Ebenezer Scrooge be forgiven at the end of A Christmas Carol?",
        "Were Romeo and Juliet’s parents responsible for their children’s deaths?",
        "Is Jane Eyre a feminist novel?",
        "Is Nick Carraway a reliable narrator in The Great Gatsby?",
        "Was Macbeth a victim of fate?"
      ]
    },
    {
      name: "Arts",
      icon: "Arts.svg",
      subcategories: [
        "Will digital art experiences replace physical art?",
        "What is the main purpose of music?",
        "Should prizes be given for the arts?",
        "Is graffiti art?",
        "Should art serve a purpose, or is it valuable for its own sake?"
      ]
    },
    {
      name: "Science",
      icon: "Science.svg",
      subcategories: [
        "Is it important to explore outer space?",
        "Should we colonize other planets?",
        "Should human doctors be replaced by automated ones?",
        "Should animal testing be banned?",
        "Should the genetic engineering of human embryos be banned?"
      ]
    },
    {
      name: "Environmental",
      icon: "Environmental.svg",
      subcategories: [
        "Is it better to buy an electric car or a gasoline car?",
        "What is the best form of renewable energy?",
        "Should people have fewer children to help tackle climate change?",
        "Should we use geoengineering to offset the impacts of climate change?",
        "Should plastic bags be banned?"
      ]
    },
    {
      name: "Geography",
      icon: "Geography.svg",
      subcategories: [
        "Will the world face a major food insecurity crisis by 2050?",
        "Has human activity affected the migrations of various species?",
        "Is the trend of rural-urban migration a positive thing?",
        "Should governments impose population controls?",
        "Should we work to conserve endangered languages?"
      ]
    },
    {
      name: "Philosophy & Ethics",
      icon: "Philosophy.svg",
      subcategories: [
        "Is it immoral for people to own pets?",
        "Is war a necessary evil?",
        "Is religious faith compatible with science?",
        "Should human cloning be legal?",
        "Should there be a death penalty?"
      ]
    },
    {
      name: "Economics & Business",
      icon: "Economics.svg",
      subcategories: [
        "Should people invest in cryptocurrency?",
        "Are price caps a useful economic tool?",
        "What is the best method for business expansion (e.g. merger, takeover, strategic alliance)?",
        "Is “delayering” an effective strategy for business to become more profitable?",
        "Should we shorten the 40-hour workweek?"
      ]
    },
    {
      name: "International Relations",
      icon: "Relations.svg",
      subcategories: [
        "Do international economic institutions, like the WTO and IMF, operate as liberal theorists intended, or do they exemplify the logic of realism?",
        "Is the US-China relationship the new Cold War?",
        "Is international law truly law?",
        "Should we lower international trade barriers?",
        "Are certain countries doomed to remain emerging economically?"
      ]
    }
  ];


  ngOnInit() {
    console.log(this.categories);
    console.log('Categories length:', this.categories.length);
    console.log('Selected Category length:', this.selectedCategory.length); // Log to check the categories data
  }

  onCategorySelect(categoryName: string): void {
    const selectedCategory = this.categories.find(category => category.name === categoryName);
    this.selectedCategory = selectedCategory ? selectedCategory.subcategories : [];
    this.categorySelected.emit(categoryName);
  }

  onSubcategorySelect(subcategoryName: string): void {
    this.subcategorySelected.emit(subcategoryName);
  }

  spinning = false;

  spin() {
    this.spinning = true;
    // Simulate spinning for a certain duration
    const spinDuration = 3000; // milliseconds
    const startTime = Date.now();
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - startTime >= spinDuration) {
        clearInterval(interval);
        this.spinning = false;
        // Select a random category
        const randomIndex = Math.floor(Math.random() * this.categories.length);
        this.selectedCategory = this.categories[randomIndex].name;
      }
    }, 100);
  }

}
