<div *ngIf="categories.length > 0">
    <mat-form-field appearance="fill">
        <mat-label>Select Category</mat-label>
        <mat-select (selectionChange)="onCategorySelect($event.value)">
            <mat-option *ngFor="let category of categories" [value]="category.name">
                <img src="assets/dropdown-icons/{{ category.icon }}" alt="{{ category.name }} icon" />
                {{ category.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="selectedCategory.length > 0">
        <mat-label>Select Subcategory</mat-label>
        <mat-select (selectionChange)="onSubcategorySelect($event.value)">
            <mat-option *ngFor="let subcategory of selectedCategory" [value]="subcategory">
                {{ subcategory }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="spinner-container">
        <div class="spinner">
            <div *ngIf="spinning" class="spinner-indicator">Spinning...</div>
            <div *ngIf="!spinning" class="selected-category">{{ selectedCategory }}</div>
        </div>
        <button (click)="spin()" [disabled]="spinning">Spin</button>
    </div>


</div>