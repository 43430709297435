export class Constants {

  //prod

  public static readonly PEER_PORT = 443;
  public static readonly PEER_SERVER = '/';
  public static readonly SOCKET_SERVER = '/';


  // socket events
  /*
    public static readonly PEER_PORT = 3000;
    public static readonly PEER_SERVER = 'localhost';
    public static readonly SOCKET_SERVER = 'localhost:3000';
  */

  public static readonly SOCKET_JOIN_ROOM = 'join-room';
  public static readonly SOCKET_MESSAGE = 'message';
  public static readonly EVENT_CONNECT = 'connect';
  public static readonly EVENT_DISCONNECT = 'disconnect';
  public static readonly SOCKET_USER_CONNECT = 'user-connected';
  public static readonly SOCKET_NOTIFICATION = 'notification';
  public static readonly SOCKET_SEND_NAME = 'sendName';
  public static readonly SOCKET_RECEIVE_NAME = 'receiveName';
  public static readonly SOCKET_END_CALL = 'callReject';
  public static readonly SOCKET_GET_TOPIC = 'getRandomTopic';
  public static readonly SOCKET_TOPIC = 'randomTopic';
}
